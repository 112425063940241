<template>
	<div class="freeCharge_page content">
		<div class="freeCharge Between flex-wrap">
			<div class="list" v-if="status == 11 || status == 14">
				<input
					type="text"
					placeholder="公司名称"
					v-model="params.companyName"
				/>
			</div>
			<div class="list" v-if="status == 11">
				<el-select
					v-model="params.typeQualification"
					clearable
					placeholder="请选择申报的类型"
				>
					<el-option
						v-for="item in optionsList"
						:key="item.value"
						:label="item.label"
						:value="item.value"
					>
					</el-option>
				</el-select>
			</div>
			<div class="list" v-if="status == 13">
				<input
					type="text"
					placeholder="认证项目"
					v-model="params.certificationProject"
				/>
			</div>

			<div class="list cascader_list" v-if="status == 13 || status == 14">
				<el-cascader
					size="large"
					:options="options"
					:props="props"
					v-model="AreaArray"
					@change="selectArea"
					placeholder="公司所在地"
					clearable
				></el-cascader>
			</div>
			<div class="list cascader_list" v-if="status == 14">
				<el-date-picker
					v-model="params.dateEstablishment"
					value-format="yyyy-MM-dd"
					format="yyyy-MM-dd"
					type="date"
					placeholder="公司成立时间"
				>
				</el-date-picker>
			</div>
			<div
				class="list"
				v-if="
					status == 8 ||
					status == 9 ||
					status == 10 ||
					status == 11 ||
					status == 12 ||
					status == 13 ||
					status == 15
				"
			>
				<input type="text" placeholder="姓名" v-model="params.userName" />
			</div>
			<div
				class="list"
				v-if="
					status == 8 ||
					status == 9 ||
					status == 10 ||
					status == 11 ||
					status == 12 ||
					status == 13 ||
					status == 14 ||
					status == 15
				"
			>
				<input
					type="text"
					placeholder="联系电话"
					v-model="params.phoneNumber"
					maxlength="11"
				/>
			</div>

			<div class="list" v-if="status == 9">
				<input
					class="longInput"
					type="text"
					placeholder="公司名称"
					v-model="params.companyName"
				/>
			</div>
			<div class="list" v-if="status == 10">
				<input
					class="longInput"
					type="text"
					placeholder="资质类型"
					v-model="params.typeQualification"
				/>
			</div>
			<div class="list" v-if="status == 11">
				<input
					class="longInput"
					type="text"
					placeholder="申报的工种"
					v-model="params.courseName"
				/>
			</div>
		</div>
		<div class="btn display pointer" @click="submitBtn">
			{{
				status == 14 ? '一键免费查询' : status == 15 ? '预约考察' : '立即获取'
			}}
		</div>
	</div>
</template>
<script>
import areaJSON from '@/assets/js/area.json'
export default {
	props: {
		status: {},
		type: {},
	},
	data() {
		return {
			AreaArray: [],
			options: areaJSON,
			props: {
				children: 'children',
				label: 'name',
				value: 'name',
			},
			value1: '',
			params: {
				userName: '',
				phoneNumber: '',
				courseId: 1,
				courseType: '',
				courseName: '',
				email: '',
				companyName: '',
				questionContent: '',
				typeQualification: '',
				certificationProject: '',
				locationEnterprise: '',
				dateEstablishment: '',
			},
			optionsList: [
				{
					value: '用人单位',
					label: '用人单位',
				},
				{
					value: '社会评价组织',
					label: '社会评价组织',
				},
			],
		}
	},
	created() {
		if (this.type != '职业技能等级评价机构申报') {
			this.params.courseName = this.type
		}
	},
	methods: {
		// 选择省市区
		selectArea(val) {
			this.params.locationEnterprise = val[0] + val[1] + val[2]
			console.log(this.params.locationEnterprise)
		},
		submitBtn() {
			this.params.courseType = this.status
			console.log(this.status)
			if (
				this.params.courseType == 8 ||
				this.params.courseType == 12 ||
				this.params.courseType == 15
			) {
				if (this.params.userName == '') {
					return this.$message.error('请输入您的姓名')
				}

				if (!/^[0-9]{11}$/.test(this.params.phoneNumber)) {
					return this.$message.error('请正确输入手机号')
				}
			}
			if (this.params.courseType == 9) {
				if (this.params.userName == '') {
					return this.$message.error('请输入您的姓名')
				}

				if (!/^[0-9]{11}$/.test(this.params.phoneNumber)) {
					return this.$message.error('请正确输入手机号')
				}
				if (this.params.companyName == '') {
					return this.$message.error('请输入公司名称')
				}
			}
			if (this.params.courseType == 10) {
				if (this.params.userName == '') {
					return this.$message.error('请输入您的姓名')
				}
				if (!/^[0-9]{11}$/.test(this.params.phoneNumber)) {
					return this.$message.error('请正确输入手机号')
				}
				if (this.params.typeQualification == '') {
					return this.$message.error('请输入资质类型')
				}
			}
			if (this.params.courseType == 11) {
				if (this.params.companyName == '') {
					return this.$message.error('请输入公司名称')
				}
				if (this.params.typeQualification == '') {
					return this.$message.error('请输入申报类型')
				}
				if (this.params.userName == '') {
					return this.$message.error('请输入您的姓名')
				}
				if (!/^[0-9]{11}$/.test(this.params.phoneNumber)) {
					return this.$message.error('请正确输入手机号')
				}
			}
			if (this.params.courseType == 13) {
				if (this.params.certificationProject == '') {
					return this.$message.error('请输入认证项目')
				}
				if (this.params.locationEnterprise == '') {
					return this.$message.error('请选择公司所在地')
				}
				if (this.params.userName == '') {
					return this.$message.error('请输入您的姓名')
				}
				if (!/^[0-9]{11}$/.test(this.params.phoneNumber)) {
					return this.$message.error('请正确输入手机号')
				}
			}
			if (this.params.courseType == 14) {
				this.params.userName = '-'
				if (this.params.companyName == '') {
					return this.$message.error('请输入公司名称')
				}
				if (this.params.locationEnterprise == '') {
					return this.$message.error('请选择公司所在地')
				}
				if (this.params.dateEstablishment == '') {
					return this.$message.error('请选择公司成立时间')
				}
				if (!/^[0-9]{11}$/.test(this.params.phoneNumber)) {
					return this.$message.error('请正确输入手机号')
				}
			}
			this.api.addCourseApply(this.params).then((res) => {
				if (res.code == 0) {
					let courseName = ''
					if (this.type != '职业技能等级评价机构申报') {
						courseName = this.type
					}

					this.$message.success('提交成功!')
					this.AreaArray = []
					this.params = {
						userName: '',
						phoneNumber: '',
						courseId: 1,
						courseType: '',
						courseName: courseName,
						email: '',
						companyName: '',
						questionContent: '',
						typeQualification: '',
						certificationProject: '',
						locationEnterprise: '',
						dateEstablishment: '',
					}
				}
			})
		},
	},
}
</script>
<style lang="scss" scoped>
.freeCharge_page {
	padding-bottom: 120px;
	.freeCharge {
		width: 670px;
		margin: 0 auto;
		margin-top: 30px;

		.list {
			margin-bottom: 24px;
			input {
				font-size: 16px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				line-height: 24px;
				background: #f4f5f5;
				width: 320px;
				height: 54px;
				background: #f4f5f5;
				padding-left: 20px;
				box-sizing: border-box;
			}
			.longInput {
				width: 669px;
			}
		}
		.cascader_list {
			::v-deep.el-input__prefix {
				display: none;
			}
		}
	}
	.btn {
		width: 671px;
		height: 54px;
		background: #ff881e;
		border-radius: 3px;
		font-size: 24px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		color: #ffffff;
		margin: 0 auto;
		margin-top: 3px;
	}
	::v-deep.el-input__inner {
		font-size: 16px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		line-height: 24px;
		background: #f4f5f5;
		width: 320px;
		height: 54px;
		background: #f4f5f5;
		padding-left: 20px;
		box-sizing: border-box;
		border: none !important;
	}
}
</style>